/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://yjljf5cnxjewnlheml3v6ymrci.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:6fcf092a-712a-4f2d-8184-1f612ea72f63",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_AcCyCK0yK",
    "aws_user_pools_web_client_id": "4mmoeo8c3q6ejm7frovvfbaa8f",
    "oauth": {
        "domain": "homehrauth-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.homehr.co.za/,http://localhost:19006/,http://localhost:19006/Login/,exp://localhost:19006/,exp://localhost:19006/--/",
        "redirectSignOut": "https://app.homehr.co.za/logout/,http://localhost:19006/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "homehrbucket234725-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
