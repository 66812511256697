import * as React from "react";
import { Divider, Title, Text, useTheme } from "react-native-paper";
import {
  DrawerScreenProps,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { View } from "react-native";
import { DrawerStackParamList } from "../types";
import LogoSVG from "../components/SVG/LogoSVG";

type Props = DrawerScreenProps<DrawerStackParamList, "Home">;

export const DrawerContent = (props: any) => {
  const { colors } = useTheme();

  return (
    <DrawerContentScrollView
      {...props}
      style={{ backgroundColor: colors.background }}
    >
      <View style={{ alignItems: "center", margin: 20 }}>
        <LogoSVG height={100} width={100} />
        <Title
          style={{
            textAlign: "center",
            color: colors.placeholder,
            fontWeight: "bold",
          }}
        >
          HOME<Title style={{ color: colors.primary }}>HR</Title>
        </Title>
        <Text style={{ textAlign: "center", color: colors.placeholder }}>
          Keeping your house in order
        </Text>
      </View>
      <Divider />
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
};

export default DrawerContent;
