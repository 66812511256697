import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { Auth } from "@aws-amplify/auth";

interface UserContext {
  user: { attributes: { email: string; name: string; sub: string } } | null;
  loading: boolean;
  login: (usernameOrEmail: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  signUp: (email: string, password: string, name: string) => Promise<any>;
  confirmSignUp: (username: string, code: string) => Promise<any>;
}

interface Props {
  children: React.ReactNode;
}

export const UserContext = createContext<UserContext | undefined>(undefined);

export const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        setLoading(true);
        const usr = await Auth.currentAuthenticatedUser();
        setUser(usr);
      } catch (err) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    getCurrentUser();
  }, []);

  const login = async (usernameOrEmail: string, password: string) => {
    try {
      const user = await Auth.signIn(usernameOrEmail, password);
      console.log(user);
      setUser(user);
      return user;
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        err.message = "Invalid username or password";
      }
      throw err;
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.log("error signing out: ", error);
      throw error;
    }
  };

  const signUp = async (email: string, password: string, name: string) => {
    try {
      const signUpResult = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
        },
      });
      return signUpResult;
    } catch (error) {
      console.log("error signing up:", error);
      setUser(null);
      throw error;
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log("error confirming sign up", error);
      throw error;
    }
  };

  const values: UserContext = useMemo(
    () => ({ user, loading, login, logout, signUp, confirmSignUp }),
    [user, loading]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      "`useUser` hook must be used within a `UserProvider` component"
    );
  }
  return context;
};
