import React, { useState, useEffect } from "react";
import { Snackbar, useTheme } from "react-native-paper";
import { useAlert } from "../contex/alert";

export const Snack = () => {
  const { colors } = useTheme();
  const { alertState, dispatchAlert } = useAlert();
  const [alertSyle, setAlertStyle] = useState({
    backgroundColor: colors.accent,
  });

  useEffect(() => {
    switch (alertState.alertType) {
      case "info":
        setAlertStyle({
          backgroundColor: colors.surface,
        });
        break;
      case "error":
        setAlertStyle({
          backgroundColor: colors.error,
        });
        break;
      case "success":
        setAlertStyle({
          backgroundColor: colors.surface,
        });
        break;
      default:
        setAlertStyle({
          backgroundColor: colors.surface,
        });
    }
  }, [alertState]);

  const closeMe = () => {
    dispatchAlert({ type: "close" });
  };

  return (
    <Snackbar
      style={alertSyle}
      visible={alertState.open}
      onDismiss={() => closeMe()}
      theme={{ colors: { accent: colors.primary, surface: colors.text } }}
      action={{
        label: "Close",
        accessibilityLabel: "Close",
        onPress: () => {
          console.log("Snackbar closed");
          // Do something
        },
      }}
    >
      {alertState.message}
    </Snackbar>
  );
};

export default Snack;
