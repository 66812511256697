import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useTheme } from "react-native-paper";
import HomeScreen from "../screens/HomeScreen";
import WorkerScreen from "../screens/WorkerScreen";
import AddWorkerScreen from "../screens/AddWorkerScreen";
import EditWorkerScreen from "../screens/EditWorkerScreen";
import TestScreen from "../screens/TestScreen";
import SettingsScreen from "../screens/SettingsScreen";
import { DrawerContent } from "./DrawerContent";
import { createStackNavigator } from "@react-navigation/stack";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const WorkerNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Worker" headerMode="none">
      <Stack.Screen
        name="Worker"
        component={WorkerScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen
        name="AddWorker"
        component={AddWorkerScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="EditWorker"
        component={EditWorkerScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

const MainDrawer = () => {
  const { colors } = useTheme();
  return (
    <Drawer.Navigator
      initialRouteName="HomeScreen"
      drawerContent={(props) => <DrawerContent {...props} />}
      drawerContentOptions={{ activeTintColor: colors.primary }}
    >
      <Drawer.Screen name="Home" component={HomeScreen} />
      <Drawer.Screen name="Worker" component={WorkerNavigator} />
      <Drawer.Screen name="Test" component={TestScreen} />
      <Drawer.Screen name="Settings" component={SettingsScreen} />
    </Drawer.Navigator>
  );
};

export default MainDrawer;
