import React from "react";
import { View, ScrollView } from "react-native";
import { Worker as WorkerModel } from "../../models";
import NoWorkers from "../SVG/NoWorkers";
import { Error } from "../States";
import Loading from "../../screens/Loading";
import Worker from "./worker";

interface Props {
  workers: WorkerModel[];
  loading: boolean;
  error: string;
  reload: () => Promise<void>;
}

export const Workers = ({ workers, loading, error, reload }: Props) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error="Could not find any workers" />;
  }

  const workerList = workers.map((w) => (
    <Worker key={w.id} worker={w} reload={reload} />
  ));

  if (workerList.length <= 0) {
    return (
      <NoWorkers height={200} width={200} style={{ alignSelf: "center" }} />
    );
  }

  return (
    <ScrollView contentContainerStyle={{ margin: 20, flexGrow: 1 }}>
      {workerList}
    </ScrollView>
  );
};

export default Workers;
