import { WorkingDay } from "../types";

export const getDaysPerWeek = (
  workingDays: Array<WorkingDay | null> | null
) => {
  let daysPerWeek = 0;
  let totalHours = 0;
  workingDays?.map((data) => {
    if (data?.isWorkDay) {
      daysPerWeek++;
      totalHours += getHoursPerDay(data?.startTime ?? "", data?.endTime ?? "");
    }
  });
  return {
    daysPerWeek: daysPerWeek,
    totalHours: totalHours,
  };
};

export const getHoursPerDay = (
  startDate: Date | String,
  endDate: Date | String
) => {
  return (Number(endDate) - Number(startDate)) / 60000 / 60;
};
