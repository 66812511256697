import React, { useState, useEffect, useCallback } from "react";
import { View } from "react-native";
import { FAB, useTheme } from "react-native-paper";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { useFocusEffect } from "@react-navigation/native";
import { DrawerStackParamList } from "../types";
import Header from "../components/Header";
import Workers from "../components/Worker";
import Snack from "../components/Snack";
import { Worker as WorkerModel } from "../models";
import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { ListWorkersQuery } from "../API";
import { listWorkers } from "../graphql/queries";

type Props = DrawerScreenProps<DrawerStackParamList, "Worker">;

type WorkerList = WorkerModel[];
const defaultEmployeeLIst = [] as WorkerList;

const WorkerScreen = ({ navigation, route }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [workers, setWorkers] = useState(defaultEmployeeLIst);
  const { colors } = useTheme();

  const getEmployees = async () => {
    setLoading(true);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(listWorkers)
      )) as GraphQLResult<ListWorkersQuery>;
      const workers = data?.listWorkers?.items as WorkerList;
      setWorkers(workers);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const addEmployee = async () => {
    navigation.navigate("AddWorker");
  };

  return (
    <View style={{ flex: 1 }}>
      <Header navigation={navigation} route={route} />
      <Workers
        workers={workers}
        loading={loading}
        error={error}
        reload={getEmployees}
      />
      <FAB
        style={{
          position: "absolute",
          margin: 16,
          right: 10,
          bottom: 20,
          backgroundColor: colors.primary,
        }}
        icon="plus"
        label="Add Worker"
        disabled={loading}
        onPress={addEmployee}
      />
      <Snack />
    </View>
  );
};

export default WorkerScreen;
