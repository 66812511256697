/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContract = /* GraphQL */ `
  mutation CreateContract($workerId: String!) {
    createContract(workerId: $workerId) {
      error
      isError
      url
    }
  }
`;
export const createWorker = /* GraphQL */ `
  mutation CreateWorker(
    $input: CreateWorkerInput!
    $condition: ModelWorkerConditionInput
  ) {
    createWorker(input: $input, condition: $condition) {
      id
      firstNames
      lastName
      knownName
      idNumber
      phoneNumber
      startDate
      workingDays {
        day
        startTime
        endTime
        options
        isWorkDay
      }
      payCalculation
      payAmount
      paymentFrequency
      paymentType
      jobDescription
      employer {
        fullName
        idNumber
        address
      }
      occupation
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateWorker = /* GraphQL */ `
  mutation UpdateWorker(
    $input: UpdateWorkerInput!
    $condition: ModelWorkerConditionInput
  ) {
    updateWorker(input: $input, condition: $condition) {
      id
      firstNames
      lastName
      knownName
      idNumber
      phoneNumber
      startDate
      workingDays {
        day
        startTime
        endTime
        options
        isWorkDay
      }
      payCalculation
      payAmount
      paymentFrequency
      paymentType
      jobDescription
      employer {
        fullName
        idNumber
        address
      }
      occupation
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorker = /* GraphQL */ `
  mutation DeleteWorker(
    $input: DeleteWorkerInput!
    $condition: ModelWorkerConditionInput
  ) {
    deleteWorker(input: $input, condition: $condition) {
      id
      firstNames
      lastName
      knownName
      idNumber
      phoneNumber
      startDate
      workingDays {
        day
        startTime
        endTime
        options
        isWorkDay
      }
      payCalculation
      payAmount
      paymentFrequency
      paymentType
      jobDescription
      employer {
        fullName
        idNumber
        address
      }
      occupation
      owner
      createdAt
      updatedAt
    }
  }
`;
