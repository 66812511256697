import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Portal,
  Card,
  Chip,
  useTheme,
  Searchbar
} from "react-native-paper";
import { View, ScrollView } from "react-native";

interface Props {
  jobDescription: Array<string>;
  setJobDescription: React.Dispatch<React.SetStateAction<string[]>>;
}

export const JobDescription = ({
  jobDescription,
  setJobDescription,
}: Props) => {
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  const [visible, setVisible] = useState(false);
  const [jobDescriptionSearch, setJobDescriptionSearch] = React.useState('');

  const jobDescriptionToggle = (name: string) => {
    const result = jobDescription.filter((item) => item === name);
    result.length > 0 ? removeJobDescription(name) : jobDescriptionAdd(name);
  };

  const jobDescriptionAdd = (name: string) => {
    const arr = [...jobDescription, name];
    setJobDescription(arr);
  };

  const removeJobDescription = (name: string) => {
    const arr = jobDescription.filter((e) => e !== name);
    setJobDescription(arr);
  };

  const isJobDescriptionChecked = (name: string) => {
    const result = jobDescription.filter((item) => item === name);
    return result.length > 0 ? "checked" : "unchecked";
  };

  const { colors } = useTheme();


  const onChangeJobDescriptionSearch = (query: string) => {
    setJobDescriptionSearch(query);
    const results = jobDescriptionListDefault.filter(name => name.toLowerCase().includes(query.toLowerCase()));
    setJobDescriptionList(results);
  }

  const jobDescriptionListDefault = [
    "Child minding / baby sitting",
    "Minding old/sick employer or elative",
    "General tidying of house",
    "Making of beds",
    "Vacuuming of carpets",
    "Vacuuming of upholstery",
    "Dusting",
    "Wiping down of all appliances e.g. T.V etc",
    "Cleaning of walls, light switches, doors etc",
    "Cleaning of ornaments",
    "Cleaning of toilets, basins, baths, shhers, taps etc and other longs list stuffs",
    "Mopping of tiled/vinyl floors",
    "Cleaning of inside of cupboards",
    "Cleaning of stove and oven",
    "Preperation/cooking of breakfast",
    "preperation/cooking of lunch",
  ];
  const [jobDescriptionList, setJobDescriptionList] = React.useState(jobDescriptionListDefault);

  const chips = jobDescription.map((data) => {
    return (
      <Chip
        key={data}
        style={{ flexWrap: "wrap", marginRight: 5, marginTop: 5 }}
        onClose={() => removeJobDescription(data)}
      >
        {data}
      </Chip>
    );
  });

  const checkBoxes = jobDescriptionList.map((data) => {
    return (
      <Checkbox.Item
        label={data}
        key={data}
        status={isJobDescriptionChecked(data)}
        labelStyle={{ color: colors.text }}
        onPress={() => jobDescriptionToggle(data)}
        // style={{
        //   borderColor: isJobDescriptionChecked(data) === "checked" ? colors.primary : "",
        //   borderWidth: 1
        // }}
      />
    );
  });

  return (
    <View style={{ flex: 1, width: "100%", marginTop: 20 }}>
      <Card style={{ width: "100%" }}>
        <Card.Title title="Job Description" />
        <Card.Content style={{ flexWrap: "wrap", flexDirection: "row" }}>
          {chips}
        </Card.Content>
        <Card.Actions>
          <Button onPress={showDialog}>Add</Button>
        </Card.Actions>
      </Card>
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog} style={{maxWidth: 600, width: "100%", alignSelf: "center"}}>
          {/* <Dialog.Title>Job Descriptions</Dialog.Title> */}
          <Searchbar
            placeholder="Search Job Description"
            onChangeText={onChangeJobDescriptionSearch}
            value={jobDescriptionSearch}
          />
          <Dialog.ScrollArea style={{ height: 300 }}>
            <ScrollView>
              <Dialog.Content
                style={{ flexDirection: "column", flexWrap: "wrap" }}
              >
                {checkBoxes}
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions>
            <Button onPress={hideDialog}>Done</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

export default JobDescription;
