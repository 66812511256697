import React, { memo } from "react";
import {
  ImageBackground,
  StyleSheet,
  KeyboardAvoidingView,
} from "react-native";
import { useTheme } from "../contex/theme";

type Props = {
  children: React.ReactNode;
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    width: "100%",
  },
  container: {
    flex: 1,
    padding: 20,
    width: "100%",
    maxWidth: 340,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});

const Background = ({ children }: Props) => {
  const { theme } = useTheme();
  // eslint-disable-next-line global-require
  const backgroundDot =
    theme === "light"
      ? require("../../assets/images/background_dot.png")
      : require("../../assets/images/background_dot_dark.png");
  return (
    <ImageBackground
      source={backgroundDot}
      resizeMode="repeat"
      style={styles.background}
    >
      <KeyboardAvoidingView style={styles.container} behavior="padding">
        {children}
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};

export default memo(Background);
