import React from "react";
import { View } from "react-native";
import { RadioButton, Card } from "react-native-paper";
import Cache from "@aws-amplify/cache";
import { useTheme } from "../contex/theme";
import { Themes } from "../types";
import Header from "../components/Header";

export default function SettingsScreen({ navigation, route }: any) {
  const { theme, setTheme } = useTheme();
  const [value, setValue] = React.useState(theme as string);

  const setThemeAndCache = async (themeValue: Themes) => {
    try {
      setTheme(themeValue);
      Cache.setItem("theme", themeValue);
    } catch (e) {
      console.log("could not save");
    }
  };

  return (
    <View>
      <Header navigation={navigation} route={route} />
      <Card style={{ margin: 20 }}>
        <Card.Title title="Theme" />
        <Card.Content>
          <RadioButton.Group
            onValueChange={(val) => {
              setValue(val);
              setThemeAndCache(val as Themes);
            }}
            value={value}
          >
            <RadioButton.Item label="System Default" value="no-preference" />
            <RadioButton.Item label="Dark" value="dark" />
            <RadioButton.Item label="Light" value="light" />
          </RadioButton.Group>
        </Card.Content>
      </Card>
    </View>
  );
}
