import React, { useEffect, useState } from "react";
import { Platform, Image, TouchableOpacity } from "react-native";
import { Button } from "react-native-paper";
import { Auth } from "@aws-amplify/auth";

export function SocialButtons() {
  return (
    <Button
      mode="contained"
      uppercase={false}
      color="#4385F4"
      style={{ marginLeft: 0, flexDirection: "row", height: 40, marginTop: 40 }}
      contentStyle={{ marginLeft: 0, flexDirection: "row", height: 40 }}
      labelStyle={{ marginLeft: 50 }}
      onPress={() => Auth.federatedSignIn({ provider: "Google" })}
    >
      <Image
        source={require("../../assets/images/google.png")}
        style={[
          {
            width: 40,
            height: 40,
            marginLeft: 0,
            position: "absolute",
            left: 0,
            top: 0,
          },
        ]}
      />
      Sign in with Google
    </Button>
  );
}
