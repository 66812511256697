import React from "react";
import { View, ScrollView, StyleSheet, Platform } from "react-native";
import { Text, FAB, Card, Paragraph, Button } from "react-native-paper";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { DrawerStackParamList } from "../types";
import Header from "../components/Header";
import Welcome from "../components/SVG/Welcome";
import Settings from "../components/SVG/Settings";

type Props = DrawerScreenProps<DrawerStackParamList, "Home">;

const HomeScreen = ({ navigation, route }: Props) => {
  return (
    <View style={{ flex: 1 }}>
      <Header navigation={navigation} route={route} />
      <ScrollView style={{ flex: 1 }} contentContainerStyle={style.HomeView}>
        <Card style={{ margin: 20 }}>
          <Card.Title title="Workers" subtitle="Manage workers from here" />
          <Card.Content>
            <Welcome width={200} height={200} />
          </Card.Content>
          <Card.Actions>
            <Button
              onPress={() => {
                navigation.navigate("Worker");
              }}
            >
              Workers
            </Button>
          </Card.Actions>
        </Card>
        <Card style={{ margin: 20 }}>
          <Card.Title title="Settings" subtitle="Change current theme" />
          <Card.Content>
            <Settings width={200} height={200} />
          </Card.Content>
          <Card.Actions>
            <Button
              onPress={() => {
                navigation.navigate("Settings");
              }}
            >
              Settings
            </Button>
          </Card.Actions>
        </Card>
      </ScrollView>
    </View>
  );
};

const style = StyleSheet.create({
  HomeView: {
    justifyContent: "center",
    ...Platform.select({
      web: {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    }),
  },
});

export default HomeScreen;
