import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import Cache from "@aws-amplify/cache";
import { Themes } from "../types";

interface ThemeContext {
  theme: Themes;
  setTheme: React.Dispatch<React.SetStateAction<Themes>>;
  isDark: Function;
}

interface Props {
  children: React.ReactNode;
  defaultTheme: boolean;
}

export const ThemeContext = createContext<ThemeContext | undefined>(undefined);

export const ThemeProvider = ({ children, defaultTheme }: Props) => {
  const [theme, setTheme] = useState<Themes>("no-preference");

  const isDark = () => {
    if (theme == "no-preference") {
      return defaultTheme;
    }
    return theme !== "light";
  };

  useEffect(() => {
    const getTheme = () => {
      const selTheme = Cache.getItem("theme");
      if (selTheme != null) {
        setTheme(selTheme);
      }
    };
    getTheme();
  }, []);

  const values: ThemeContext = useMemo(() => ({ theme, setTheme, isDark }), [
    theme,
  ]);

  return (
    <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error(
      "`useTheme` hook must be used within a `ThemeProvider` component"
    );
  }
  return context;
};
