import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

const LogoSVG = (props: any) => {
  return (
    <Svg
      viewBox="0 0 400 400"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      {...props}
    >
      <Path fill="none" d="M0 0h400v400H0z" />
      <Circle cx={57.7} cy={217.985} r={16.773} fill="#32b4a1" />
      <Circle cx={342.3} cy={217.985} r={16.773} fill="#5e5d5b" />
      <Path
        d="M57.7 172.644S81.558 58.261 200.867 58.261c109.262 0 141.433 114.383 141.433 114.383l-70.33 3.091-71.103-66.466-77.286 66.466"
        fill="none"
        stroke="#2fb69e"
        strokeWidth={35.6}
      />
      <Path
        d="M329.847 257.526S286.281 341.739 200 341.739c-90.792 0-130.687-84.213-130.687-84.213l98.499 2.256v-53.761h58.648v54.137l43.987 1.88"
        fill="none"
        stroke="#5e5d5b"
        strokeWidth={35.6}
      />
    </Svg>
  );
};

export default LogoSVG;
