import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import React from "react";
import AuthStack from "./AuthStack";
import MainDrawer from "./MainDrawer";
import Loading from "../screens/Loading";
import LinkingConfiguration from "./LinkingConfiguration";
import { useUser } from "../contex/user";

const Navigation = ({ isDark }: { isDark: Function }) => {
  const { user, loading } = useUser();
  const theme = isDark() ? DarkTheme : DefaultTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      fallback={<Loading />}
      theme={theme}
    >
      <RootNavigator user={user} />
    </NavigationContainer>
  );
};

interface UserProp {
  user: {} | null;
}

const RootNavigator = (props: UserProp) => {
  const { user } = props;
  if (user) {
    return <MainDrawer />;
  }
  return <AuthStack />;
};

export default Navigation;
