import { DrawerScreenProps } from "@react-navigation/drawer";
import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import React, { useEffect, useState } from "react";
import { GetWorkerQuery } from "../API";
import { WorkerModify } from "../components/Worker/worker-modify";
import { getWorker } from "../graphql/queries";
import { DrawerStackParamList } from "../types";
import Loading from "./Loading";

type Props = DrawerScreenProps<DrawerStackParamList, "EditWorker">;

type Worker = GetWorkerQuery["getWorker"] | null;

const EditWorkerScreen = ({ navigation, route }: Props) => {
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null as Worker);

  useEffect(() => {
    const initEmployees = async () => {
      const workerId = route?.params?.id;
      if (workerId && workerId.length > 0) getEmployee(workerId);
    };
    initEmployees();
  }, []);

  const getEmployee = async (id: string) => {
    setLoading(true);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getWorker, { id: id })
      )) as GraphQLResult<GetWorkerQuery>;

      const worker = data?.getWorker as Worker;
      setWorker(worker);
      setLoading(false);
    } catch (err) {
      // setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <WorkerModify
      navigation={navigation}
      route={route}
      worker={worker}
      edit={true}
    />
  );
};

export default EditWorkerScreen;
