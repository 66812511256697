import React, { memo, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { Text, useTheme, Card } from "react-native-paper";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { emailValidator, passwordValidator } from "../../util/validators";
import { NavigationVerificationCode } from "../../types";
import styles from "./AuthStyles";
import { useUser } from "../../contex/user";
import { AuthError } from "../../components/AuthError";
import { SocialButtons } from "../../components/SocialButtons";

type Props = {
  navigation: NavigationVerificationCode;
  route: any;
};

const LoginScreen = ({ navigation, route }: Props) => {
  const username = route?.params?.username ?? "";
  const [email, setEmail] = useState({ value: username, error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { login } = useUser();
  const theme = useTheme();
  const style = styles(theme);

  const onLoginPressed = async () => {
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }

    try {
      setLoading(true);
      await login(email.value, password.value);
      setLoading(false);
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        navigation.navigate("VerifyEmail", {
          username: email.value,
          destination: "",
        });
      }
      console.log("error signing in", error);
      setError(error.message);
      // TODO: show error sign in
      setLoading(false);
    }
  };

  return (
    <Background>
      <Logo />
      <TextInput
        label="Email"
        returnKeyType="next"
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: "" })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />
      <TextInput
        label="Password"
        returnKeyType="done"
        value={password.value}
        onChangeText={(text) => setPassword({ value: text, error: "" })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />
      <View style={style.forgotPassword}>
        <TouchableOpacity onPress={() => navigation.navigate("ForgotPassword")}>
          <Text style={style.label}>Forgot your password?</Text>
        </TouchableOpacity>
      </View>
      <AuthError error={error} />
      <Button
        mode="contained"
        onPress={onLoginPressed}
        loading={loading}
        disabled={loading}
      >
        Login
      </Button>

      <View style={style.row}>
        <Text style={style.label}>Don’t have an account? </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Register")}>
          <Text style={style.link}>Sign up</Text>
        </TouchableOpacity>
      </View>

      <SocialButtons />
    </Background>
  );
};

export default memo(LoginScreen);
