import React, { memo, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { emailValidator } from "../../util/validators";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { Navigation } from "../../types";
import styles from "./AuthStyles";
import { useTheme } from "react-native-paper";

type Props = {
  navigation: Navigation;
};

const ForgotPasswordScreen = ({ navigation }: Props) => {
  const [email, setEmail] = useState({ value: "", error: "" });

  const theme = useTheme();
  const style = styles(theme);

  const onSendPressed = () => {
    const emailError = emailValidator(email.value);

    if (emailError) {
      setEmail({ ...email, error: emailError });
      return;
    }

    navigation.navigate("LoginScreen");
  };

  return (
    <Background>
      <Logo />
      <TextInput
        label="E-mail address"
        returnKeyType="done"
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: "" })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />

      <Button mode="contained" onPress={onSendPressed} style={style.button}>
        Send Reset Instructions
      </Button>

      <TouchableOpacity
        style={style.back}
        onPress={() => navigation.navigate("Login")}
      >
        <Text style={style.label}>← Back to login</Text>
      </TouchableOpacity>
    </Background>
  );
};

export default memo(ForgotPasswordScreen);
