import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  Card,
  useTheme,
  Text,
  Switch,
  ToggleButton,
  HelperText,
} from "react-native-paper";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import Loading from "../screens/Loading";
import { WorkingDay } from "../types";
import { getDaysPerWeek, getHoursPerDay } from "../util/working-days-util";
import dayjs from "dayjs";

const Timepicker = lazy(() => import("../components/Timepicker"));

interface Props {
  workingDays: Array<WorkingDay | null> | null;
  setWorkingDays: React.Dispatch<
    React.SetStateAction<Array<WorkingDay | null> | null>
  >;
}

export const WorkingDays = ({ workingDays, setWorkingDays }: Props) => {
  const { colors } = useTheme();

  const startDateTime = dayjs().hour(8).minute(0).second(0).millisecond(0);
  const endDateTime = dayjs().hour(16).minute(0).second(0).millisecond(0);

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [startDate, setStartDate] = useState(startDateTime);
  const [endDate, setEndDate] = useState(endDateTime);

  const setTimes = () => {
    const results = workingDays?.map((data) => {
      if (data) {
        data.startTime = startDate.second(0).millisecond(0).format("HH:mm");
        data.endTime = endDate.second(0).millisecond(0).format("HH:mm");
        return data;
      } else {
        return null;
      }
    });
    setWorkingDays(results ?? null);
  };

  useEffect(setTimes, [startDate, endDate]);

  const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn);

  const isWorkingDaysChecked = (name: string) => {
    const result = workingDays?.filter((item) => item?.day === name);
    if (result == undefined || result == null) {
      return false;
    }
    return result?.length > 0 && result[0]?.isWorkDay;
  };

  const workingDaysChecked = (name: string) => {
    const result = workingDays?.filter((item) => item?.day === name);
    if (result == undefined || result == null) {
      return "unchecked";
    }
    return result.length > 0 && result[0]?.isWorkDay ? "checked" : "unchecked";
  };

  const workingDaysToggle = (name: string) => {
    const result = workingDays?.filter((item) => item?.day === name);
    let newDay: WorkingDay | null;
    if (result != undefined && result.length <= 0) {
      newDay = {
        day: name,
        startTime: startDate.second(0).millisecond(0).format("HH:mm"),
        endTime: endDate.second(0).millisecond(0).format("HH:mm"),
        options: "",
        isWorkDay: true,
      } as WorkingDay;
      const newArray = [
        ...(workingDays?.filter((item) => item?.day !== name) ?? []),
        newDay,
      ];
      setWorkingDays(newArray);
    }
  };

  const hoursPerDay = getHoursPerDay(startDate.toDate(), endDate.toDate());
  const total = getDaysPerWeek(workingDays);

  interface Helper {
    type: "info" | "error";
    show: boolean;
    message: string;
  }

  const helper: Helper = {
    type: hoursPerDay > 0 && hoursPerDay <= 10 ? "info" : "error",
    show: true,
    message: `Works ${hoursPerDay} hour(s) a day, ${total.daysPerWeek} day(s) per week, ${total.totalHours} hour(s) per week`,
  };

  const styles = StyleSheet.create({
    toggleButton: {
      borderColor: colors.accent,
      borderWidth: 2,
      margin: 1,
    },
    toggleButtonActive: {
      backgroundColor: colors.primary,
      margin: 1,
    },
  });

  const daysList = ["M", "T", "W", "Th", "F", "Sa", "Su"];

  const toggleButtons = daysList.map((data) => {
    return (
      <ToggleButton
        value={data}
        key={data}
        status={workingDaysChecked(data)}
        onPress={() => workingDaysToggle(data)}
        icon={() => <Text>{data}</Text>}
        style={[
          isWorkingDaysChecked(data)
            ? styles.toggleButtonActive
            : styles.toggleButton,
        ]}
      />
    );
  });

  return (
    <Suspense fallback={<Loading />}>
      <Card
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginTop: 20,
        }}
      >
        <Card.Title
          title="Working Days"
          right={() => (
            <TouchableOpacity
              onPress={onToggleSwitch}
              style={{ flexDirection: "row" }}
            >
              <Text>Advanced</Text>
              <Switch
                value={isSwitchOn}
                onValueChange={onToggleSwitch}
                style={{ marginLeft: 20, marginRight: 20 }}
              />
            </TouchableOpacity>
          )}
        />
        <Card.Content style={{ flexDirection: "column", flexWrap: "wrap" }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {toggleButtons}
          </View>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <Timepicker
              label="Start Time"
              value={startDate}
              onChange={setStartDate}
            />
            <Timepicker
              label="End Time"
              value={endDate}
              onChange={setEndDate}
            />
            <HelperText type={helper.type} visible={helper.show}>
              {helper.message}
            </HelperText>
          </View>
        </Card.Content>
      </Card>
    </Suspense>
  );
};

export default WorkingDays;
