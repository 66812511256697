import React from "react";
import { Card, Text, useTheme } from "react-native-paper";

interface Props {
  error: string;
}

export const AuthError = ({ error }: Props) => {
  const { colors } = useTheme();
  if (error.length <= 0) {
    return null;
  }
  return (
    <Card
      style={{
        backgroundColor: colors.error,
        width: "100%",
        padding: 20,
      }}
    >
      <Text style={{ color: colors.background }}>{error}</Text>
    </Card>
  );
};
