export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export default delay;

export const getInitials = (name: string) => {
  var names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
