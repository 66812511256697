import React, { useState } from "react";
import { View } from "react-native";
import {
  Card,
  Avatar,
  Button,
  Dialog,
  Portal,
  Paragraph,
  useTheme,
} from "react-native-paper";
import { API, graphqlOperation } from "@aws-amplify/api";
import {
  createContract,
  deleteWorker as deleteWorkerMutation,
} from "../../graphql/mutations";
import * as Linking from "expo-linking";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { CreateContractResponse, Worker as WorkerModel } from "../../models";
import { getInitials } from "../../util";
import { WorkerOptions } from "./worker-options";
import { DeleteWorkerInput, DeleteWorkerMutation } from "../../API";
import { useNavigation } from "@react-navigation/native";

interface Test {
  createContract: CreateContractResponse;
}

interface Props {
  worker: WorkerModel;
  reload: () => Promise<void>;
}

export const Worker = ({ worker, reload }: Props) => {
  const [loading, setLoading] = useState(false);

  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const hideDialog = () => setConfirmDeleteVisible(false);

  const { colors } = useTheme();

  const generateContractMethod = async (worker: WorkerModel) => {
    setLoading(true);
    const result = (await API.graphql(
      graphqlOperation(createContract, { workerId: worker.id })
    )) as GraphQLResult<Test>;
    console.log(result);
    if (!result.data?.createContract?.isError) {
      Linking.openURL(result.data?.createContract?.url ?? "");
    }
    setLoading(false);
  };

  const navigation = useNavigation();
  const editWorker = async (worker: WorkerModel) => {
    navigation.navigate("EditWorker", { id: worker.id });
  };

  const deleteWorker = async (worker: WorkerModel) => {
    setConfirmDeleteVisible(true);
  };

  const deleteWorkerConfirmed = async (worker: WorkerModel) => {
    setLoading(true);
    const result = (await API.graphql(
      graphqlOperation(deleteWorkerMutation, {
        input: {
          id: worker.id,
        },
      } as DeleteWorkerInput)
    )) as GraphQLResult<DeleteWorkerMutation>;
    if (!result.errors) {
      await reload();
    }
    setLoading(false);
  };

  const initials = getInitials(worker.firstNames);

  return (
    <View>
      <Portal>
        <Dialog visible={confirmDeleteVisible} onDismiss={hideDialog}>
          <Dialog.Title>Delete {worker.firstNames}?</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Are you sure you want to remove worker?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideDialog}>Cancel</Button>
            <Button
              mode="contained"
              color={colors.error}
              onPress={() => deleteWorkerConfirmed(worker)}
            >
              Delete
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <Card style={{ marginTop: 20 }}>
        <Card.Title
          title={worker.firstNames + " " + worker.lastName}
          subtitle={worker.idNumber}
          left={(props) => <Avatar.Text {...props} label={initials} />}
          right={(props) => (
            <WorkerOptions
              editWorker={editWorker}
              deleteWorker={deleteWorker}
              worker={worker}
            />
          )}
        />
        <Card.Actions>
          <Button
            mode="contained"
            onPress={() => generateContractMethod(worker)}
            loading={loading}
            disabled={loading}
          >
            Generate Contract
          </Button>
        </Card.Actions>
      </Card>
    </View>
  );
};

export default Worker;
