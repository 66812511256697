import React from "react";
import { Appbar } from "react-native-paper";
import {
  DrawerNavigationProp,
  DrawerScreenProps,
} from "@react-navigation/drawer";
import { DrawerStackParamList } from "../types";
import UserAvatar from "./UserAvatar";
import { RouteProp } from "@react-navigation/native";

interface Props {
  navigation: DrawerNavigationProp<
    DrawerStackParamList,
    "Home" | "Test" | "Worker" | "AddWorker" | "EditWorker"
  >;
  route: RouteProp<
    DrawerStackParamList,
    "Home" | "Test" | "Worker" | "AddWorker" | "EditWorker"
  >;
  back?: "Worker";
  title?: string;
}

const Header = ({ navigation, route, back, title }: Props) => {
  const appBar = !back ? (
    <Appbar.Action icon="menu" onPress={() => navigation.toggleDrawer()} />
  ) : (
    <Appbar.BackAction onPress={() => navigation.navigate(back)} />
  );
  return (
    <Appbar.Header>
      {appBar}
      <Appbar.Content title={title ?? route.name} />
      <UserAvatar />
    </Appbar.Header>
  );
};

export default Header;
