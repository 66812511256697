import React, { useMemo, createContext, useContext, useReducer } from "react";

interface Props {
  children: React.ReactNode;
}

interface AlertState {
  type?: "open" | "close";
  open: boolean;
  alertType?: "info" | "error" | "success";
  message?: string;
}

interface AlertAction {
  type: "open" | "close";
  open?: boolean;
  alertType?: "info" | "error" | "success";
  message?: string;
}

const initialState: AlertState = {
  type: "close",
  open: false,
  alertType: "info",
  message: "",
};

interface AlertContext {
  alertState: AlertState;
  dispatchAlert: React.Dispatch<AlertAction>;
}

export const AlertContext = createContext<AlertContext | undefined>(undefined);

const reducer = (state: AlertState, action: AlertAction) => {
  console.log(state, action);
  switch (action.type) {
    case "close":
      return {
        ...initialState,
      };
    case "open":
      return {
        open: true,
        alertType: action.alertType,
        message: action.message,
      };
    default:
      throw new Error();
  }
};

export const AlertProvider = ({ children }: Props) => {
  const [alertState, dispatchAlert] = useReducer(reducer, initialState);

  const values: AlertContext = useMemo(() => ({ alertState, dispatchAlert }), [
    alertState,
  ]);

  return (
    <AlertContext.Provider value={values}>{children}</AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error(
      "`useAlert` hook must be used within a `AlertProvider` component"
    );
  }
  return context;
};
