import {
  DefaultTheme as LightThemePaper,
  DarkTheme as DarkThemePaper,
} from "react-native-paper";

export const LightTheme = {
  ...LightThemePaper,
  dark: false,
  colors: {
    ...LightThemePaper.colors,
    primary: "#03dac4",
    accent: "#5E5D5B",
  },
};

export const DarkTheme = {
  ...DarkThemePaper,
  dark: true,
  colors: {
    ...DarkThemePaper.colors,
    primary: "#03dac4",
    accent: "#5E5D5B",
  },
};
