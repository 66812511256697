/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWorker = /* GraphQL */ `
  query GetWorker($id: ID!) {
    getWorker(id: $id) {
      id
      firstNames
      lastName
      knownName
      idNumber
      phoneNumber
      startDate
      workingDays {
        day
        startTime
        endTime
        options
        isWorkDay
      }
      payCalculation
      payAmount
      paymentFrequency
      paymentType
      jobDescription
      employer {
        fullName
        idNumber
        address
      }
      occupation
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listWorkers = /* GraphQL */ `
  query ListWorkers(
    $filter: ModelWorkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstNames
        lastName
        knownName
        idNumber
        phoneNumber
        startDate
        workingDays {
          day
          startTime
          endTime
          options
          isWorkDay
        }
        payCalculation
        payAmount
        paymentFrequency
        paymentType
        jobDescription
        employer {
          fullName
          idNumber
          address
        }
        occupation
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
