import React, { useState } from "react";
import { View } from "react-native";
import { Provider, Menu, Button, IconButton } from "react-native-paper";
import { Worker as WorkerModel } from "../../models";

interface Props {
  editWorker: (worker: WorkerModel) => Promise<void>;
  deleteWorker: (worker: WorkerModel) => Promise<void>;
  worker: WorkerModel;
}

export const WorkerOptions = ({ editWorker, deleteWorker, worker }: Props) => {
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  const fireAction = (
    worker: WorkerModel,
    action: (worker: WorkerModel) => Promise<void>
  ) => {
    closeMenu();
    action(worker);
  };

  return (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      anchor={<IconButton icon="dots-vertical" onPress={openMenu} />}
    >
      <Menu.Item
        onPress={() => fireAction(worker, editWorker)}
        icon="circle-edit-outline"
        title="Edit"
      />
      <Menu.Item
        onPress={() => fireAction(worker, deleteWorker)}
        icon="delete-circle"
        title="Delete"
      />
    </Menu>
  );
};

export default WorkerOptions;
