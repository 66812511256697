import * as React from "react";
import Svg, { Path, Ellipse, Circle } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

function SvgComponent(props: any) {
  return (
    <Svg
      data-name="Layer 1"
      width={996.611}
      height={804}
      viewBox="0 0 996.611 804"
      {...props}
    >
      <Path
        d="M467.223 8.98c-50.937 13.232-95.866 48.559-140.524 94.905a76.2 76.2 0 01-99.835 115.087c-52.457 62.664-109.734 128.91-179.641 183.663-177.996 139.408 188.04 393.655 420 393.655s420-176.245 420-393.655-196.468-451.725-420-393.656z"
        fill="#f2f2f2"
      />
      <Ellipse cx={532.611} cy={773.5} rx={464} ry={30.5} fill="#3f3d56" />
      <Ellipse cx={545.611} cy={771} rx={160} ry={17} opacity={0.1} />
      <Path
        d="M937.351 739.506c0 29.271-17.399 39.49-38.865 39.49a61.495 61.495 0 01-4.443-.154c-19.373-1.369-34.422-12.115-34.422-39.336 0-28.167 35.993-63.712 38.704-66.349l.004-.003c.103-.104.157-.154.157-.154s38.865 37.238 38.865 66.506z"
        fill="#03dac4"
      />
      <Path
        d="M897.07 774.518l14.215-19.86-14.251 22.042-.036 2.279a61.495 61.495 0 01-2.955-.136l1.531-29.282-.01-.23.025-.038.146-2.77-14.287-22.095 14.33 20.021.036.587 1.155-22.125-12.23-22.832 12.38 18.951 1.206-45.873.004-.157v.154l-.2 36.172 12.176-14.341-12.227 17.456-.322 19.81 11.372-19.012-11.418 21.928-.179 11.013 16.501-26.463-16.565 30.305z"
        fill="#3f3d56"
      />
      <Circle cx={476.793} cy={192.764} r={47.763} fill="#2f2e41" />
      <Path
        fill="#a0616a"
        d="M586.73 614.807l25.529 102.937 25.528-4.118-25.528-117.76-25.529 18.941z"
      />
      <Path
        d="M621.317 711.156s-14.538 4.915-12.622 8.222 1.917 23.894-4.671 31.306-23.058 30.469-4.118 31.292 37.88-11.529 37.88-11.529v-21.41l3.295 19.764h3.294v-22.235s8.235-14 3.294-22.234-8.162-15.412-14.375-14.706-11.977 1.53-11.977 1.53z"
        fill="#2f2e41"
      />
      <Path
        fill="#a0616a"
        d="M486.263 702.097l-4.117 22.235h28.822v-22.235h-24.705z"
      />
      <Path
        d="M488.734 719.39s-8.235-.823-10.706 10.706-24.704 28-32.94 30.47-8.234 10.705 2.471 12.352 37.88 2.47 44.469-6.588a80.723 80.723 0 0115.646-15.646l-4.117 23.058h7.411s6.588-22.235 13.176-29.646-13.176-24.705-16.47-24.705z"
        fill="#2f2e41"
      />
      <Path
        d="M448.383 365.287s-5.765 15.646-15.647 18.117-83.173 19.764-83.173 19.764-64.233 1.647-44.469 15.646 51.88 0 51.88 0 93.88-11.529 101.29-23.881a166.461 166.461 0 0012.353-26.352z"
        fill="#a0616a"
      />
      <Path
        d="M479.675 296.113s-46.939 76.585-41.174 78.232 59.291 18.94 61.762 12.353 2.47-37.058 2.47-37.058z"
        fill="#2f2e41"
      />
      <Path
        d="M479.675 296.113s-46.939 76.585-41.174 78.232 59.291 18.94 61.762 12.353 2.47-37.058 2.47-37.058z"
        opacity={0.1}
      />
      <Path
        d="M601.553 277.996l106.231 54.351s60.116 15.646 46.94 27.999-55.998-10.706-55.998-10.706l-117.76-53.527zM465.676 235.174s20.587 31.293 18.94 38.705c0 0 1.647 4.94-.823 8.235s-1.647 23.058-1.647 23.058l8.235 11.529 10.705-35.41 4.941-37.882s-11.117-15.234-9.47-22.646-30.881 14.411-30.881 14.411z"
        fill="#a0616a"
      />
      <Path
        d="M530.732 413.05s-46.116 123.524-46.116 177.875-17.293 116.113-4.117 121.878 79.88 6.588 81.526 0-8.235-274.225-13.176-284.93-18.117-14.823-18.117-14.823z"
        fill="#2f2e41"
      />
      <Path
        d="M530.732 413.05s-46.116 123.524-46.116 177.875-17.293 116.113-4.117 121.878 79.88 6.588 81.526 0-8.235-274.225-13.176-284.93-18.117-14.823-18.117-14.823z"
        opacity={0.1}
      />
      <Circle cx={476.381} cy={209.646} r={30.469} fill="#a0616a" />
      <Path
        d="M485.44 301.878l-2.205-18.585s-6.854 9.526-6.854 16.938-4.117 33.763 13.176 50.233 20.588 19.764 21.411 23.058 4.941 3.294 4.941 3.294l23.882 96.349s7.411 256.108 18.94 259.402 46.94 0 49.41-4.118-4.117-113.642-4.117-113.642 18.116 97.172 24.704 97.172 10.706-14 9.059-14.823-11.53-64.233-15.647-83.997-2.47-186.933-2.47-186.933 13.176-21.411-32.116-63.41c0 0-28.823-83.996-30.47-83.996s-33.763-45.293-33.763-45.293-18.117 0-21.411 10.706-16.47 57.645-16.47 57.645z"
        fill="#2f2e41"
      />
      <Path
        d="M513.439 240.115l9.882-6.588s41.175 24.705 69.997 26.352 27.999 18.117 16.47 29.646-56.821 51.057-58.468 41.175-37.881-90.585-37.881-90.585z"
        fill="#2f2e41"
      />
      <Ellipse
        cx={476.793}
        cy={179.588}
        rx={32.94}
        ry={19.764}
        fill="#2f2e41"
      />
      <Circle cx={480.087} cy={145.001} r={19.764} fill="#2f2e41" />
      <Path
        d="M453.735 134.296a19.766 19.766 0 0117.705-19.657 19.982 19.982 0 00-2.058-.107 19.764 19.764 0 000 39.528 19.982 19.982 0 002.059-.107 19.766 19.766 0 01-17.706-19.657z"
        fill="#2f2e41"
      />
      <Path
        fill="#3f3d56"
        d="M310.048 414.986l435.364-73.224 42.763 254.252L352.81 669.24z"
      />
      <Path
        d="M683.919 425.581l-289.133 48.097a5.947 5.947 0 11-1.952-11.733l289.133-48.097a5.947 5.947 0 011.952 11.733z"
        fill="#03dac4"
        opacity={0.3}
      />
      <Circle cx={604.165} cy={432.82} r={24.638} fill="#03dac4" />
      <Path
        d="M694.654 490.112L405.52 538.21a5.947 5.947 0 11-1.952-11.732l289.133-48.097a5.947 5.947 0 011.952 11.732z"
        fill="#03dac4"
        opacity={0.3}
      />
      <Circle cx={484.161} cy={519.099} r={24.638} fill="#03dac4" />
      <Path
        d="M705.388 554.644L416.256 602.74a5.947 5.947 0 11-1.952-11.733l289.132-48.097a5.947 5.947 0 111.952 11.733z"
        fill="#03dac4"
        opacity={0.3}
      />
      <Circle cx={584.569} cy={568.713} r={24.638} fill="#03dac4" />
      <Circle cx={275.37} cy={160.201} r={54.315} fill="#ff6584" />
    </Svg>
  );
}

export default SvgComponent;
