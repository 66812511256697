import React, { memo } from "react";
import { Image, StyleSheet } from "react-native";
import LogoSVG from "./SVG/LogoSVG";

const styles = StyleSheet.create({
  image: {
    width: 128,
    height: 128,
    marginBottom: 12,
  },
});

const Logo = () => <LogoSVG height={128} width={128} />;

export default memo(Logo);
