import React, { memo, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { verificationCode } from "../../util/validators";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import styles from "./AuthStyles";
import { useTheme, Dialog, Portal, Paragraph } from "react-native-paper";
import { Auth } from "@aws-amplify/auth";
import { useUser } from "../../contex/user";

type Props = {
  navigation: any;
  route: any;
};

const VerifyEmailScreen = ({ navigation, route }: Props) => {
  const [code, setCode] = useState({ value: "", error: "" });
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { confirmSignUp } = useUser();
  const username = route?.params?.username ?? "";
  const destination = route?.params?.destination ?? "";

  const theme = useTheme();
  const style = styles(theme);

  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  const onSendPressed = async () => {
    const codeError = verificationCode(code.value);

    if (codeError) {
      setCode({ ...code, error: codeError });
      return;
    }

    try {
      setLoading(true);
      await confirmSignUp(username, code.value);
      setLoading(false);
      showDialog();
    } catch (error) {
      // TODO: Show sign up confirm error
      console.log("error confirming sign up", error);
      setLoading(false);
    }
  };

  const goToLogin = async () => {
    hideDialog();
    navigation.navigate("Login", {
      username: username,
    });
  };

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  };

  return (
    <Background>
      <Logo />
      <Paragraph>We emailed the verification code to: {destination}</Paragraph>
      <TextInput
        label="Verification Code"
        returnKeyType="done"
        value={code.value}
        onChangeText={(text) => setCode({ value: text, error: "" })}
        error={!!code.error}
        errorText={code.error}
        autoCapitalize="none"
        autoCompleteType="off"
        textContentType="oneTimeCode"
        keyboardType="number-pad"
      />

      <Button
        mode="contained"
        onPress={onSendPressed}
        style={style.button}
        loading={loading}
      >
        Verify
      </Button>

      <TouchableOpacity style={style.forgotPassword} onPress={resendCode}>
        <Text style={style.link}>Re-send code</Text>
      </TouchableOpacity>
      <Portal>
        <Dialog
          visible={visible}
          onDismiss={hideDialog}
          style={{ alignSelf: "center", maxWidth: 400 }}
        >
          <Dialog.Title>Email Verified</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Email has been successfully verified.</Paragraph>
            <Paragraph>You can now login and get started.</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={goToLogin}>Login</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Background>
  );
};

export default memo(VerifyEmailScreen);
