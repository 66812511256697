import * as SplashScreen from "expo-splash-screen";
import React, { useState, useEffect } from "react";
import * as Linking from "expo-linking";

export const useOAuthURL = () => {
  const [isUrlDone, setUrlDone] = useState(false);
  const [url, setUrl] = useState({ redirectSignIn: "", redirectSignOut: "" });

  useEffect(() => {

    const getUrl = (url: string) => {
      const splitUrl = url.split("/");
      let newUrl = "";
      if (splitUrl.length >= 3) {
        newUrl = `${splitUrl[0]}//${splitUrl[2]}/`;
      }
      return { redirectSignIn: newUrl, redirectSignOut: `${newUrl}logout/` };
    }

    const getOAuthUrl = async () => {
      try {
        SplashScreen.preventAutoHideAsync();
        const url = await Linking.getInitialURL();
        setUrl(getUrl(url ?? ""));
      } catch (e) {
        console.warn(e);
      } finally {
        setUrlDone(true);
        SplashScreen.hideAsync();
      }
    }
    getOAuthUrl();
  }, []);

  return { isUrlDone, url };
}
