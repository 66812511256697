import React from "react";
import { Text } from "react-native-paper";
import { View } from "react-native";

export default function NotFoundScreen() {
  return (
    <View>
      <Text>404 not found</Text>
    </View>
  );
}
