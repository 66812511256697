import React, { memo, useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { Text, useTheme } from "react-native-paper";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { NavigationVerificationCode } from "../../types";
import styles from "./AuthStyles";
import {
  emailValidator,
  passwordValidator,
  nameValidator,
} from "../../util/validators";
import { useUser } from "../../contex/user";

interface Props {
  navigation: NavigationVerificationCode;
}

const RegisterScreen = ({ navigation }: Props) => {
  const [name, setName] = useState({ value: "", error: "" });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const style = styles(theme);
  const { signUp } = useUser();

  const onSignUpPressed = async () => {
    const nameError = nameValidator(name.value);
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);

    if (emailError || passwordError || nameError) {
      setName({ ...name, error: nameError });
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }

    try {
      setLoading(true);
      const { user, codeDeliveryDetails } = await signUp(
        email.value,
        password.value,
        name.value
      );
      setLoading(false);
      navigation.navigate("VerifyEmail", {
        username: email.value,
        destination: codeDeliveryDetails.Destination,
      });
    } catch (err) {
      console.log("sing up failed, ", err);
      // TODO: show sign up error message
      setLoading(false);
    }
  };

  return (
    <Background>
      <Logo />
      <TextInput
        label="Name"
        returnKeyType="next"
        value={name.value}
        onChangeText={(text) => setName({ value: text, error: "" })}
        error={!!name.error}
        errorText={name.error}
      />

      <TextInput
        label="Email"
        returnKeyType="next"
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: "" })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />

      <TextInput
        label="Password"
        returnKeyType="done"
        value={password.value}
        onChangeText={(text) => setPassword({ value: text, error: "" })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />

      <Button
        mode="contained"
        onPress={onSignUpPressed}
        style={style.button}
        loading={loading}
      >
        Sign Up
      </Button>

      <View style={style.row}>
        <Text style={style.label}>Already have an account? </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Login")}>
          <Text style={style.link}>Login</Text>
        </TouchableOpacity>
      </View>
    </Background>
  );
};

export default memo(RegisterScreen);
