import React from "react";
import { Provider as PaperProvider } from "react-native-paper";
import Navigation from "./navigation";
import { DarkTheme, LightTheme } from "./theme";
import { useTheme } from "./contex/theme";
import { AlertProvider } from "./contex/alert";

export default function Main() {
  const { isDark } = useTheme();
  const paperTheme = isDark() ? DarkTheme : LightTheme;

  return (
    <PaperProvider theme={paperTheme}>
      <AlertProvider>
        <Navigation isDark={isDark} />
      </AlertProvider>
    </PaperProvider>
  );
}
