import React, { memo } from "react";
import { View, StyleSheet, Text } from "react-native";
import { TextInput as Input, HelperText } from "react-native-paper";

type Props = React.ComponentProps<typeof Input> & { errorText?: string };

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginVertical: 12,
  },
});

const TextInput = ({ errorText, ...props }: Props) => {
  return (
    <View style={styles.container}>
      <Input underlineColor="transparent" mode="flat" {...props} />
      <HelperText type="error" visible={!!errorText}>
        {errorText}
      </HelperText>
    </View>
  );
};
export default TextInput;
