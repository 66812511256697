import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { Text, Button } from "react-native-paper";
import { DrawerScreenProps } from "@react-navigation/drawer";
import { DrawerStackParamList } from "../types";
import Header from "../components/Header";

type Props = DrawerScreenProps<DrawerStackParamList, "Test">;

const initialLocationState = {
  lat: null,
  lon: null,
  speed: null,
};

const TestScreen = ({ navigation, route }: Props) => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [networkStatus, setNetworkStatus] = useState(navigator.onLine);
  const [{ lat, lon, speed }, setLocation] = useState(initialLocationState);

  let mounted = true;

  useEffect(() => {
    window.addEventListener("mousemove", handleouseMove);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    navigator.geolocation.getCurrentPosition(handleGeolocation);
    const watchId = navigator.geolocation.watchPosition(handleGeolocation);

    return () => {
      window.removeEventListener("mousemove", handleouseMove);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      navigator.geolocation.clearWatch(watchId);
      mounted = false;
    };
  }, []);

  const handleouseMove = (event: any) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
  };

  const handleOnline = () => {
    setNetworkStatus(true);
  };

  const handleOffline = () => {
    setNetworkStatus(false);
  };

  const handleGeolocation = (event: any) => {
    if (mounted) {
      setLocation({
        lat: event.coords.latitude,
        lon: event.coords.longitude,
        speed: event.coords.speed,
      });
    }
  };

  return (
    <>
      <Header navigation={navigation} route={route} />
      <Text>X: {mousePosition.x}</Text>
      <Text>Y: {mousePosition.y}</Text>
      <Text>Network Status: {networkStatus ? "online" : "offline"}</Text>
      <Text>Location</Text>
      <Text>lat: {lat}</Text>
      <Text>lon: {lon}</Text>
      <Text>speed: {speed}</Text>
    </>
  );
};

export default TestScreen;
