import React, { useState } from "react";
import {
  Avatar,
  Menu,
  Divider,
  Text,
  Button,
  Surface,
  useTheme,
} from "react-native-paper";
import { TouchableHighlight, View } from "react-native";
// import { DataStore } from "@aws-amplify/datastore";
import { useUser } from "../contex/user";
import style from "../style";
import { getInitials } from "../util/";

const UserAvatar = () => {
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const { colors } = useTheme();

  const { logout, user } = useUser();
  const signOut = async () => {
    // await DataStore.clear();
    await logout();
  };

  const getUserAttributes = {
    email: user?.attributes?.email,
    name: user?.attributes?.name,
    initial: getInitials(user?.attributes?.name ?? ""),
  };

  return (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      contentStyle={style.avatar}
      anchor={
        <Surface style={{ backgroundColor: colors.primary, borderRadius: 50 }}>
          <TouchableHighlight onPress={openMenu} style={{ borderRadius: 50 }}>
            <Avatar.Text size={36} label={getUserAttributes.initial} />
          </TouchableHighlight>
        </Surface>
      }
    >
      <View style={{ margin: 20 }}>
        <Avatar.Text
          size={64}
          label={getUserAttributes.initial}
          style={{ alignSelf: "center" }}
        />
        <Text style={{ alignSelf: "center", margin: 20 }}>
          {getUserAttributes.name}
        </Text>
        <Text style={{ alignSelf: "center" }}>{getUserAttributes.email}</Text>
      </View>

      <Divider />
      <Button mode="contained" onPress={signOut} style={{ margin: 20 }}>
        Sign out
      </Button>
    </Menu>
  );
};

export default UserAvatar;
