import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");
export const maxWidth = Math.min(width - 20, 400);

const style = StyleSheet.create({
  container: {
    minWidth: "300px",
    width: "50%",
    alignSelf: "center",
    marginTop: "10px",
  },
  avatar: {
    width: maxWidth,
  },
});

export default style;
