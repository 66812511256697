import { DrawerScreenProps } from "@react-navigation/drawer";
import React from "react";
import { WorkerModify } from "../components/Worker/worker-modify";
import { DrawerStackParamList } from "../types";

type Props = DrawerScreenProps<DrawerStackParamList, "AddWorker">;

const AddWorkerScreen = ({ navigation, route }: Props) => {
  return (
    <WorkerModify
      navigation={navigation}
      route={route}
      worker={null}
      edit={false}
    />
  );
};

export default AddWorkerScreen;
