import { StatusBar } from "expo-status-bar";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AppearanceProvider, useColorScheme } from "react-native-appearance";
import Amplify from "@aws-amplify/core";
import useCachedResources from "./src/hooks/useCachedResources";
import { useOAuthURL } from "./src/hooks/useOAuthURL";
import Main from "./src/main";
import awsconfig from "./aws-exports";
import { UserProvider } from "./src/contex/user";
import { ThemeProvider } from "./src/contex/theme";
import { Splash } from "./src/screens/Splash";
// import { enableScreens } from "react-native-screens";

const App = () => {
  const { isUrlDone, url } = useOAuthURL();
  const isLoadingComplete = useCachedResources();
  // enableScreens();

  if (isUrlDone) {
    const amplifyConfig = {
      ...awsconfig,
      oauth: {
        ...awsconfig.oauth,
        redirectSignIn: url.redirectSignIn,
        redirectSignOut: url.redirectSignOut,
      },
    };
    Amplify.configure(amplifyConfig);
  }

  if (!isLoadingComplete || !isUrlDone) {
    return <Splash />;
  }
  return (
    <SafeAreaProvider>
      <AppearanceProvider>
        <AppContent />
      </AppearanceProvider>
    </SafeAreaProvider>
  );
};

const AppContent = () => {
  const colorScheme = useColorScheme();
  const isDark = colorScheme !== "light";
  return (
    <ThemeProvider defaultTheme={isDark}>
      <UserProvider>
        <Main />
        <StatusBar />
      </UserProvider>
    </ThemeProvider>
  );
};

export default App;
