import React from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

export const Splash = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
      }}
    >
      <ActivityIndicator size="large" color="#03dac4" />
    </View>
  );
};

export default Splash;
