import { StyleSheet } from "react-native";
import { Theme } from "react-native-paper/lib/typescript/src/types";

export const styles = (theme: Theme) =>
  StyleSheet.create({
    back: {
      width: "100%",
      marginTop: 12,
    },
    button: {
      marginTop: 12,
    },
    label: {
      color: theme.colors.accent,
      width: "100%",
    },
    forgotPassword: {
      width: "100%",
      alignItems: "flex-end",
      marginBottom: 24,
    },
    row: {
      flexDirection: "row",
      marginTop: 4,
    },
    link: {
      fontWeight: "bold",
      color: theme.colors.primary,
    },
  });

export default styles;
