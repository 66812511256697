import React, { useState, Suspense, lazy, useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useAlert } from "../../contex/alert";
import {
  useTheme,
  Button,
  RadioButton,
  Card,
  Checkbox,
  Chip,
  Divider,
} from "react-native-paper";
import dayjs from "dayjs";
import { RouteProp, CompositeNavigationProp } from "@react-navigation/native";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { StackNavigationProp } from "@react-navigation/stack";
import { DrawerStackParamList, WorkerStackParamList } from "../../types";
import Header from "../../components/Header";
import TextInput from "../../components/TextInput";
import Snack from "../../components/Snack";
import { API, graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import {
  CreateWorkerInput,
  CreateWorkerMutation,
  UpdateWorkerInput,
  UpdateWorkerMutation,
} from "../../API";
import { createWorker, updateWorker } from "../../graphql/mutations";
import { UserSVG } from "../SVG/UserSVG";
import { EmployerSVG } from "../SVG/EmployerSVG";
import Loading from "../../screens/Loading";
import { JobDescription } from "../../components/job-description";
import { WorkingDays } from "../../components/working-days";
import { WorkingDay } from "../../types";
import { GetWorkerQuery, EmployerInput } from "../../API";
import { getWorker } from "../../graphql/queries";
import { delay } from "../../util";

const Datepicker = lazy(() => import("../../components/Datepicker.web"));

type NavigationProp = CompositeNavigationProp<
  | DrawerNavigationProp<DrawerStackParamList, "EditWorker">
  | DrawerNavigationProp<DrawerStackParamList, "AddWorker">,
  StackNavigationProp<WorkerStackParamList>
>;

type Props = {
  navigation: NavigationProp;
  route:
    | RouteProp<DrawerStackParamList, "EditWorker">
    | RouteProp<DrawerStackParamList, "AddWorker">;
  worker: GetWorkerQuery["getWorker"] | null;
  edit: boolean;
};

export const WorkerModify = ({ navigation, route, edit, worker }: Props) => {
  const [loading, setLoading] = useState(false);

  const [employerFullName, setEmployerFullName] = useState({
    value: worker?.employer.fullName,
    error: "",
  });
  const [employerINumber, setEmployerINumber] = useState({
    value: worker?.employer.idNumber,
    error: "",
  });
  const [employerAddress, setEmployerAddress] = useState({
    value: worker?.employer.address,
    error: "",
  });

  const getEmployer = () => {
    return {
      address: employerAddress.value,
      fullName: employerFullName.value,
      idNumber: employerINumber.value,
    } as EmployerInput;
  };

  const [firstNames, setFirstNames] = useState({
    value: worker?.firstNames,
    error: "",
  });
  const [lastName, setLastName] = useState({
    value: worker?.lastName,
    error: "",
  });
  const [knownName, setKnownName] = useState({
    value: worker?.knownName,
    error: "",
  });
  const [idNumber, setIdNumber] = useState({
    value: worker?.idNumber,
    error: "",
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: worker?.phoneNumber,
    error: "",
  });
  const [startDate, setStartDate] = useState(
    dayjs(worker?.startDate) ?? dayjs()
  );

  const [
    workingDays,
    setWorkingDays,
  ] = useState<Array<WorkingDay | null> | null>(worker?.workingDays ?? []);
  const [payCalculation, setPayCalculation] = useState(
    worker?.payCalculation ?? "daily"
  );
  const [payAmount, setPayAmount] = useState({
    value: worker?.payAmount ?? 0,
    error: "",
  });

  const [paymentFrequency, setPaymentFrequency] = useState(
    worker?.paymentFrequency ?? "monthly"
  );
  const [paymentType, setPaymentType] = useState({
    value: worker?.paymentType ?? [""],
    error: "",
  });
  const [jobDescription, setJobDescription] = useState<Array<string>>(
    worker?.jobDescription ?? []
  );
  const [occupation, setOccupation] = useState({
    value: worker?.occupation,
    error: "",
  });

  const isPaymentTypeChecked = (name: string) => {
    const result = paymentType.value.filter((item) => item === name);
    return result.length > 0 ? "checked" : "unchecked";
  };

  const paymentTypeToggle = (name: string) => {
    const result = paymentType.value.filter((item) => item === name);
    result.length > 0 ? paymentTypeRemove(name) : paymentTypeAdd(name);
  };

  const paymentTypeAdd = (name: string) => {
    const arr = [...paymentType.value, name];
    setPaymentType({ value: arr, error: "" });
  };

  const paymentTypeRemove = (name: string) => {
    const arr = paymentType.value.filter((e) => e !== name);
    setPaymentType({ value: arr, error: "" });
  };

  const { dispatchAlert } = useAlert();

  const addWorker = async () => {
    const worker = {
      firstNames: firstNames.value,
      lastName: lastName.value,
      knownName: knownName.value,
      idNumber: idNumber.value,
      phoneNumber: phoneNumber.value,
      startDate: startDate.format("YYYY-MM-DD"),
      workingDays: workingDays,
      payCalculation: payCalculation.toString(),
      paymentFrequency: paymentFrequency,
      payAmount: payAmount.value,
      paymentType: paymentType.value,
      jobDescription: jobDescription,
      occupation: occupation.value,
      employer: getEmployer(),
    } as CreateWorkerInput;

    try {
      setLoading(true);
      const { data } = (await API.graphql(
        graphqlOperation(createWorker, { input: worker })
      )) as GraphQLResult<CreateWorkerMutation>;

      dispatchAlert({
        type: "open",
        open: true,
        alertType: "info",
        message: "Succesfully created new worker",
      });
      setLoading(false);
      navigation.replace("Worker");
    } catch (err) {
      setLoading(false);
      if (err.errors != undefined && err.errors?.length > 0) {
        dispatchAlert({
          type: "open",
          open: true,
          alertType: "error",
          message: err.errors[0].message,
        });
      }
    }
  };

  const editWorker = async (workerId: string) => {
    const worker = {
      id: workerId,
      firstNames: firstNames.value,
      lastName: lastName.value,
      knownName: knownName.value,
      idNumber: idNumber.value,
      phoneNumber: phoneNumber.value,
      startDate: startDate.format("YYYY-MM-DD"),
      workingDays: workingDays,
      payCalculation: payCalculation.toString(),
      paymentFrequency: paymentFrequency,
      payAmount: payAmount.value,
      paymentType: paymentType.value,
      jobDescription: jobDescription,
      occupation: occupation.value,
      employer: getEmployer(),
    } as UpdateWorkerInput;

    try {
      setLoading(true);
      const { data } = (await API.graphql(
        graphqlOperation(updateWorker, { input: worker })
      )) as GraphQLResult<UpdateWorkerMutation>;

      dispatchAlert({
        type: "open",
        open: true,
        alertType: "info",
        message: "Succesfully updated worker",
      });
      setLoading(false);
      navigation.replace("Worker");
    } catch (err) {
      setLoading(false);
      if (err.errors != undefined && err.errors?.length > 0) {
        dispatchAlert({
          type: "open",
          open: true,
          alertType: "error",
          message: err.errors[0].message,
        });
      }
    }
  };

  const { colors } = useTheme();
  const styles = StyleSheet.create({
    container: {
      padding: 20,
      width: "100%",
      alignSelf: "center",
      alignItems: "center",
    },
    toggleButton: {
      borderColor: colors.accent,
      borderWidth: 2,
      margin: 1,
    },
    toggleButtonActive: {
      backgroundColor: colors.primary,
      margin: 1,
    },
  });

  const btn = edit ? (
    <Button
      onPress={() => editWorker(worker?.id ?? "")}
      loading={loading}
      style={{ width: "100%", padding: 10, margin: 20 }}
      labelStyle={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}
      mode="contained"
    >
      Edit Worker
    </Button>
  ) : (
    <Button
      onPress={addWorker}
      loading={loading}
      style={{ width: "100%", padding: 10, margin: 20 }}
      labelStyle={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}
      mode="contained"
    >
      Add Worker
    </Button>
  );

  return (
    <View style={{ flex: 1 }}>
      <Header navigation={navigation} route={route} back="Worker" />
      <Suspense fallback={<Loading />}>
        <ScrollView contentContainerStyle={styles.container}>
          <Chip avatar={<EmployerSVG />}>Employer Details</Chip>
          <Divider style={{ width: "100%", margin: 10 }} />
          <TextInput
            label="Employer Full Name"
            returnKeyType="next"
            value={employerFullName.value}
            onChangeText={(text) =>
              setEmployerFullName({ value: text, error: "" })
            }
            error={!!employerFullName.error}
            errorText={employerFullName.error}
            autoCapitalize="words"
            autoCompleteType="off"
            keyboardType="default"
          />
          <TextInput
            label="Employer Id Number"
            returnKeyType="next"
            value={employerINumber?.value?.toString()}
            onChangeText={(text) =>
              setEmployerINumber({ value: parseInt(text), error: "" })
            }
            error={!!idNumber.error}
            errorText={idNumber.error}
            autoCapitalize="none"
            autoCompleteType="off"
            textContentType="none"
            keyboardType="numeric"
          />
          <TextInput
            label="Employer Address"
            returnKeyType="next"
            value={employerAddress.value}
            onChangeText={(text) =>
              setEmployerAddress({ value: text, error: "" })
            }
            error={!!employerAddress.error}
            errorText={employerAddress.error}
            autoCapitalize="words"
            autoCompleteType="off"
            keyboardType="default"
          />
          <Chip avatar={<UserSVG />}>Employee Details</Chip>
          <Divider style={{ width: "100%", margin: 10 }} />
          <TextInput
            label="First Names"
            returnKeyType="next"
            value={firstNames.value}
            onChangeText={(text) => setFirstNames({ value: text, error: "" })}
            error={!!firstNames.error}
            errorText={firstNames.error}
            autoCapitalize="words"
            autoCompleteType="name"
            textContentType="givenName"
            keyboardType="default"
          />
          <TextInput
            label="Last Name"
            returnKeyType="next"
            value={lastName.value}
            onChangeText={(text) => setLastName({ value: text, error: "" })}
            error={!!lastName.error}
            errorText={lastName.error}
            autoCapitalize="words"
            autoCompleteType="off"
            textContentType="familyName"
            keyboardType="default"
          />
          <TextInput
            label="Known As"
            returnKeyType="next"
            value={knownName.value ?? ""}
            onChangeText={(text) => setKnownName({ value: text, error: "" })}
            error={!!knownName.error}
            errorText={knownName.error}
            autoCapitalize="words"
            autoCompleteType="username"
            textContentType="nickname"
            keyboardType="default"
          />
          <TextInput
            label="Occupation"
            returnKeyType="next"
            value={occupation.value ?? ""}
            onChangeText={(text) => setOccupation({ value: text, error: "" })}
            error={!!occupation.error}
            errorText={occupation.error}
            autoCapitalize="words"
            autoCompleteType="off"
            textContentType="jobTitle"
            keyboardType="default"
          />
          <TextInput
            label="Phone number"
            returnKeyType="next"
            value={phoneNumber.value}
            onChangeText={(text) => setPhoneNumber({ value: text, error: "" })}
            error={!!phoneNumber.error}
            errorText={phoneNumber.error}
            autoCapitalize="none"
            autoCompleteType="tel"
            textContentType="telephoneNumber"
            keyboardType="phone-pad"
          />
          <TextInput
            label="Id Number"
            returnKeyType="next"
            value={idNumber.value}
            onChangeText={(text) => setIdNumber({ value: text, error: "" })}
            error={!!idNumber.error}
            errorText={idNumber.error}
            autoCapitalize="none"
            autoCompleteType="off"
            textContentType="none"
            keyboardType="default"
          />
          <Datepicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />

          <WorkingDays
            workingDays={workingDays}
            setWorkingDays={setWorkingDays}
          />

          <Card
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Card.Title title="Payment Rate" />
            <Card.Content style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <RadioButton.Group
                onValueChange={(value) => setPayCalculation(value)}
                value={payCalculation}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <RadioButton.Item
                    testID="hourly"
                    label="Hourly"
                    value="hourly"
                  />
                  <RadioButton.Item
                    testID="daily"
                    label="Daily"
                    value="daily"
                  />
                  <RadioButton.Item
                    testID="monthly"
                    label="Monthly"
                    value="monthly"
                  />
                </View>

                <TextInput
                  label="Rate"
                  returnKeyType="next"
                  value={payAmount?.value?.toString()}
                  onChangeText={(text) =>
                    setPayAmount({ value: parseFloat(text), error: "" })
                  }
                  error={!!payAmount.error}
                  errorText={payAmount.error}
                  autoCapitalize="none"
                  autoCompleteType="off"
                  textContentType="none"
                  keyboardType="default"
                />
              </RadioButton.Group>
            </Card.Content>
          </Card>

          <Card
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Card.Title title="Payment Frequency" />
            <Card.Content style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <RadioButton.Group
                onValueChange={(value) => setPaymentFrequency(value)}
                value={paymentFrequency}
              >
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <RadioButton.Item
                    testID="monthly"
                    label="Monthly"
                    value="monthly"
                  />
                  <RadioButton.Item
                    testID="weekly"
                    label="Weekly"
                    value="weekly"
                  />
                  <RadioButton.Item
                    testID="daily"
                    label="Daily"
                    value="daily"
                  />
                </View>
              </RadioButton.Group>
            </Card.Content>
          </Card>

          <Card
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Card.Title title="Payment Type" />
            <Card.Content style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Checkbox.Item
                label="Cash"
                status={isPaymentTypeChecked("Cash")}
                onPress={() => paymentTypeToggle("Cash")}
              />
              <Checkbox.Item
                label="Eft"
                status={isPaymentTypeChecked("Eft")}
                onPress={() => paymentTypeToggle("Eft")}
              />
              <Checkbox.Item
                label="Other"
                status={isPaymentTypeChecked("Other")}
                onPress={() => paymentTypeToggle("Other")}
              />
            </Card.Content>
          </Card>

          <JobDescription
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
          />
          {btn}
        </ScrollView>
        <Snack />
      </Suspense>
    </View>
  );
};
