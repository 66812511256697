import React from "react";
import { View } from "react-native";
import ErrorSVG from "../SVG/ErrorSVG";
import { Title, useTheme } from "react-native-paper";

interface Props {
  error: string;
}

export const Error = ({ error }: Props) => {
  const { colors } = useTheme();
  return (
    <View style={{ marginTop: 20 }}>
      <ErrorSVG height={200} width={200} style={{ alignSelf: "center" }} />
      <Title style={{ alignSelf: "center", color: colors.error }}>
        {error}
      </Title>
    </View>
  );
};
